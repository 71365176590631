<template>
  <div class="container">
    <div class="section">
      <div class="columns is-multiline">
        <div class="column is-12 title-section">
          <h1>Empleos</h1>
          <button
            class="button is-primary"
            @click="openModalNuevo"
          >Crear empleo</button>
        </div>
        <div class="column is-12">
          <div class="card">
            <table class="table is-fullwidth is-narrow is-hoverable">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Nombre</th>
                  <th>Empresa</th>
                  <th>Área</th>
                  <th>Estatus</th>
                  <!-- <th>Acciones</th> -->
                </tr>
              </thead>
              <tbody>
                <template v-if="!loading">
                  <tr v-for="el in data" :key="el.id">
                    <td>
                      {{el.id}}
                    </td>
                    <td>
                      <router-link :to="`/vacante/detalle/${el.id}`">
                      {{el.name}}
                      </router-link>
                    </td>
                    <td>
                      {{el.company}}
                    </td>
                    <td>
                      {{el.area}}
                    </td>
                    <td>
                      {{el.status}}
                    </td>
                    <!-- <td>
                      Editar / Eliminar
                    </td> -->
                  </tr>
                </template>
                <tr v-if="data.length === 0 && !loading">
                  <td colspan="6" class="has-text-centered">
                    Sin información
                  </td>
                </tr>
                <tr v-if="loading">
                  <td colspan="6" class="has-text-centered is-loading">
                    <div class="lds-dual-ring" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <modal-nueva-vacante
      v-if="modalNuevo"
      :close="openModalNuevo"
      :crearVacante="crearVacante"
    />
  </div>
</template>

<script>
import api from '@/libs/api'
export default {
  name: 'Postulantes',
  components: {
    ModalNuevaVacante: () => import('@/components/vacantes/ModalNuevaVacante')
  },
  data () {
    return {
      data: [],
      loading: false,
      modalNuevo: false
    }
  },
  methods: {
    async getVacantes (page) {
      const response = await api.getVacantes(page)
      if (response.success) {
        this.data = response.payload
      }
    },
    openModalNuevo () {
      this.modalNuevo = !this.modalNuevo
    },
    async crearVacante (vacanteNueva) {
      const resp = await api.crearVacante(vacanteNueva)
      if (resp.success) {
        this.getVacantes(1)
        this.openModalNuevo()
      }
    }
  },
  mounted () {
    this.loading = true
    this.getVacantes(1)
      .then(_ => {
        this.loading = false
      })
  }
}
</script>

<style scoped>
</style>
